//Break point
@mixin xxs-min {
	@media (max-width: 320px) {
		@content;
	}
}

@mixin xxs {
	@media (max-width: 375px) {
		@content;
	}
}

@mixin xs {
	@media (max-width: 576px) {
		@content;
	}
}

@mixin sm {
	@media (min-width: 576px) {
		@content;
	}
}

@mixin md {
	@media (min-width: 768px) {
		@content;
	}
}

@mixin lg {
	@media (min-width: 992px) {
		@content;
	}
}

@mixin xl {
	@media (min-width: 1200px) {
		@content;
	}
}

@mixin xxl {
	@media (min-width: 1440px) {
		@content;
	}
}
