.code-editor {
    span {
        font-family: SF Mono, Consolas, 'Courier New', monospace;
    }
}

.challenge-tabs {
    &__item {
        writing-mode: vertical-lr;
    }
}

.form-item-editor {
    .ql-container {
        min-height: 12rem;
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .ql-editor {
        height: 100%;
        flex: 1;
        overflow-y: auto;
    }

    &.ant-form-item-has-error {
        .ql-container,
        .ql-editor,
        .ql-toolbar {
            border-color: #ff4d4f;
        }
    }
}
.filter {
    .ant-input {
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        position: relative;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        position: relative;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
        border-color: #d9d9d9;
    }
}
.ant-table-thead > tr > th {
    color: #6e7191 !important;
    background: #f2f2f2;
    border-bottom: 1px solid #a0a3bd;
    white-space: nowrap;
    font-weight: 600;
    transition: all 0.3s ease;
}

.ant-table-tbody .ant-table-row {
    td {
        border-bottom: 1px solid #e5e7f1;
    }
    &:hover {
        td {
            background-color: #f6fbff;
        }
    }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
    padding: 15px 10px !important;
}
.ant-table-thead th.ant-table-column-has-sorters {
    padding: 0 !important;
}
.ant-table-column-sorters {
    padding: 15px 10px !important;
}

tr.ant-table-measure-row > td {
    padding: 0 !important;
}
