.ant-btn-primary-cus {
    color: #fff;
    border-color: #1890ff !important;
    background-color: #1890ff !important;
    &:focus {
        border-color: #40a9ff !important;
        background-color: #40a9ff !important;
    }
    &:hover {
        border-color: #40a9ff !important;
        background-color: #40a9ff !important;
    }
    &:active {
        border-color: #096dd9 !important;
        background-color: #096dd9 !important;
    }
}
.anticon {
    &-custom {
        & svg {
            margin-bottom: 5px;
        }
    }
}

.ant-form-item-explain-error {
    margin: 6px 0 16px 0;
    font-size: 13px;
}

.ant-form-item-label {
    text-align: left;
}
.ant-btn.ant-btn-block {
    width: unset !important;
}

.ant-btn {
    & svg {
        margin-bottom: 5px;
    }
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    &::placeholder {
        color: #ff4d4f;
    }
}

.ant-select.ant-select-in-form-item.ant-select-status-error.ant-select-single.ant-select-show-arrow {
    .ant-select-selector {
        input::placeholder {
            color: #ff4d4f;
        }
    }
}
.ant-form-item-label > label {
    height: unset !important;
}

.complete-ant {
    &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: unset;
        border-right: unset;
    }
}

.ant-switch {
    background-color: #00000021;
    &.ant-switch-checked {
        background-color: #1890ff;
    }
}

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-left: 2px solid #1890ff;
}

.ant-input-password {
    input {
        background: #f2f2f2;
    }
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background: #f2f2f2;
}

.ant-table-thead > tr > th {
    border-bottom: unset !important;
}

.ant-modal-footer {
    padding: 16px 24px;
}

.ant-table-pagination.ant-pagination {
    margin: 16px 0 0 0;
}
